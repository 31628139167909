import React, {useState, useEffect} from 'react';
import {FaBars} from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import logoSVG from '../Images/LSMiamiLogoAllWhite.svg';
import Faq from '../FAQ/index';


import{
    ExternalLink,
    Logo,
    Nav,
    NavbarContainer,
    NavLogo,
    MobileIcon,
    NavMenu,
    NavItem,
    NavLinks
    } 
    from './NavbarElements';


const Navbar = ({ toggle }) => {
    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if(window.scrollY >= 80){
            setScrollNav(true)
        }else{
            setScrollNav(false)
        }
    }

    useEffect(()=> {
        window.addEventListener('scroll', changeNav)
    },[])

    const toggleHome = () => {
         scroll.scrollToTop()
    }
    return (
        <>
          <Nav scrollNav={scrollNav}>
              <NavbarContainer>
                  <NavLogo to='/' onClick={toggleHome}><Logo alt="Land and Sea Miami's logo" src={logoSVG}/></NavLogo>
                  
                  <MobileIcon onClick={toggle}>
                      <FaBars />
                  </MobileIcon>

                  <NavMenu>
                      <NavItem>
                        <NavLinks to='about'
                        smooth={true} duration={500} spy={true}
                        exact='true' offset={-80}
                        >About</NavLinks>   
                       </NavItem>
                       <NavItem>
                           <NavLinks to ='sectors'
                           smooth={true} duration={500} spy={true}
                           exact='true' offset={-80}
                           >Fleet</NavLinks>
                       </NavItem>
                       <NavItem>
                           <NavLinks to="contactus"
                           smooth={true} duration={500} spy={true}
                           exact='true' offset={-80}
                           >Contact Us</NavLinks>
                       </NavItem>
                       {/* <NavItem>
                       <Redirect push to="/somewhere/else">
                           FAQ
                      </Redirect>
                       </NavItem> */}
                       <NavItem>
                            <ExternalLink href="https://www.boat-ed.com/floridarental/" target="_blank">Boaters License</ExternalLink >
                       </NavItem>
                    
                  </NavMenu>
                       {/* <NavBtn>
                           <NavBtnLink to ='/signin'>Sign In</NavBtnLink>
                       </NavBtn> */}
              </NavbarContainer>
          </Nav>
        </> 
    );
};

export default Navbar;
