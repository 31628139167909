import styled from 'styled-components';

export const SaContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;    
    width: 100%;
    height: 1200px;

    @media only screen 
and (device-width: 428px) 
and (device-height: 926px) 
and (-webkit-device-pixel-ratio: 3) { 
    width: 900px;
}
`
export const Svg = styled.svg`
    width: 1200px;
    height: 100vh: 
    margin: 0;
    top: 50%;
    right: 50%;

@media only screen 
and (device-width: 428px) 
and (device-height: 926px) 
and (-webkit-device-pixel-ratio: 3) { 
    scale: 2.1;
    margin:0;
    width: 800px;
    height: 100vh: 
    top: 50%;
    right: 50%;
    
}
`


