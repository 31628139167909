import React, {useState} from 'react';
import { Button } from '../ButtonElement2';
import YachtSVG from '../Images/YachtSVG.svg';
import LandSVG from '../Images/LandSVG.svg';
import FrontSkiSVG from '../Images/FrontSki.svg';
import Bundle from '../Images/BundleDeal.svg';
import {
    SectorContainer,
    SectorContent, 
    SectorCard,
    SectorH1,
    SectorWrapper,
    SectorIcon,
    SectorP,
    ImgContainer,
   Card
} from './SectorElements'



const SectorServices = () => {
        const [hover, setHover] = useState(false);
    
        const onHover = () => {
            setHover(!hover)
        }
 
    return (
        <SectorContainer id='sectors'>
            <SectorWrapper>
            
                <SectorCard>
                    <ImgContainer>
                        <SectorIcon alt="Silhouette of an exotic car"src={LandSVG}/>
                    </ImgContainer>
                    <SectorContent>
                        <Card>
                            <SectorH1>
                            Slingshots
                            </SectorH1>
                            <SectorP>
                            Cruise the streets of Miami in style with our Slingshots.
                            </SectorP>
                        </Card>    
                            <Button
                            primary='true'
                            dark= 'true'
                            smooth={true} duration={500} spy={true}
                            exact='true' offset={-80}
                            to='contactus'
                            onMouseEnter={onHover}
                            onMouseLeave={onHover}
                            >BOOK</Button>    
                    </SectorContent>
                </SectorCard>
                
                
                <SectorCard>
                        <ImgContainer>
                        <SectorIcon alt="Image of a Jet Ski" src={FrontSkiSVG}/>
                        </ImgContainer>
                    <SectorContent>
                        <Card>
                            <SectorH1>Jet Skis</SectorH1>
                            <SectorP>Fast and Stylish Jet Skis to cruise the beaches of Miami.</SectorP>     
                        </Card>
                        <Button
                            primary='true'
                            dark= 'true'
                            smooth={true} duration={500} spy={true}
                            exact='true' offset={-80}
                            to='contactus'
                            onMouseEnter={onHover}
                            onMouseLeave={onHover}
                            >BOOK</Button> 
                    </SectorContent>
                </SectorCard>

                <SectorCard  >
                    <ImgContainer>
                        <SectorIcon alt="Image of Yacht" src={YachtSVG}/>
                    </ImgContainer>
                <SectorContent>
                    <Card>
                    <SectorH1>Yacht</SectorH1> 
                    <SectorP>Our selection of Yachts to enjoy the Miami Skyline with friends and family.</SectorP>     
                    </Card>
                    <Button
                        primary='true'
                        dark= 'true'
                        smooth={true} duration={500} spy={true}
                        exact='true' offset={-80}
                        to='contactus'
                        onMouseEnter={onHover}
                        onMouseLeave={onHover}
                        >BOOK</Button> 
                </SectorContent>
                </SectorCard>

                <SectorCard  >
                    <ImgContainer>
                        <SectorIcon alt="Image illustrating a package with 2 or more options combined" src={Bundle}/>
                    </ImgContainer> 
                <SectorContent>
                        <Card>
                            <SectorH1>Jet Ski Delivery</SectorH1> 
                            <SectorP>On a Yacht? No problem, we hot drop Jet Skis!    
                            </SectorP>
                            
                        </Card>
                        <Button
                            primary='true'
                            dark= 'true'
                            smooth={true} duration={500} spy={true}
                            exact='true' offset={-80}
                            to='contactus'
                            onMouseEnter={onHover}
                            onMouseLeave={onHover}>BOOK
                        </Button>     
                </SectorContent>
                </SectorCard>

                
            </SectorWrapper>
        </SectorContainer>
            
        
    )
 }
    export default SectorServices;


