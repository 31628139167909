import React,{ useState } from 'react';
import Footer from '../Components/Footer';
import HeroSection from '../Components/HeroSection';
import Gallery from '../Components/Gallery';
import Gallery2 from '../Components/Gallery2';
import InfoSection from '../Components/InfoSection';
import { homeObjOne, homeObjTwo } from '../Components/InfoSection/Data';
import Navbar from '../Components/Navbar';
import Sidebar from '../Components/Sidebar';
import SectorServices from '../Components/Sectors/index'
import 'font-awesome/css/font-awesome.min.css'



const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () =>{
        setIsOpen(!isOpen);
    };


    return (
        <>
          <Sidebar isOpen={isOpen} toggle={toggle} />
          <Navbar toggle={toggle}  />  
          <HeroSection/>
          <InfoSection {...homeObjOne}/>
          <Gallery />
          {/* <Gallery2 /> */}
          <SectorServices/>
          <InfoSection {...homeObjTwo}/>
          <Footer/>
          
        </>
    );
};

export default Home;
