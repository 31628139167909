import React from 'react'
import {SidebarContainer,
    Icon,
    ClosedIcon,
    SidebarWrapper,
    SidebarMenu,
    SidebarLink,
    ExternalLink,
     } from './SidebarElements'

const Sidebar = ({ isOpen, toggle }) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <ClosedIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="about" onClick={toggle}>
                        LS Miami
                    </SidebarLink>
                    <SidebarLink to="contactus" onClick={toggle}>
                        Contact Us
                    </SidebarLink>
                    <SidebarLink to="sectors" onClick={toggle}>
                        Services
                    </SidebarLink>
                    <SidebarLink to="sectors" onClick={toggle}>
                        Fleet
                    </SidebarLink>
                    <ExternalLink href="https://www.boat-ed.com/floridarental/" target="_blank">
                        Boaters License
                    </ExternalLink>
                </SidebarMenu>
                {/* <SideBtnWrap>
                    <SidebarRoute to='/signin'>Sign In</SidebarRoute>
                </SideBtnWrap> */}
            </SidebarWrapper>
        </SidebarContainer>
    );
};

export default Sidebar;
