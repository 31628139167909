import styled from 'styled-components';

export const GalleryContainer = styled.div`
    overflow: hidden;
    width:100%;
    height:100vh;
    color: #fff;
    background: #000;
    

    @media screen and (max-width: 490px) {
        height: 100vh;
      
    }
    @media screen and (max-width: 375px) {
        height: 100vh;
       
    }
`;