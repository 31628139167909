import './App.css';
import React,{ useState,useEffect, } from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import SA from './Components/SA/SA'
import Home from './Pages';
import SigninPage from './Pages/signin';
import Faq from './Components/FAQ/index';





function App() {
  const [loading, setLoading] = useState(false);

  let isMounted = true;

  useEffect(()=>{
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    },7000)
  },[])
  return (
          <Router>
            {
              isMounted = false,
              loading ?(<>
                <SA>
                 
                </SA>     
              </>    
              )
            
              :
                
              <>
                 <Switch>
                  <Route path="/" component={Home} exact/>
                  <Route path="/signin" component ={SigninPage} exact/>
                  {/* <Route path="gallery" component ={Gallery} exact/> */}
                  <Route path="/FAQ" component ={Faq} exact/> 
                </Switch>
              </>
            } 
          </Router>
  );
}

export default App;
