    import styled from 'styled-components';
import { Link } from 'react-scroll';

export const Button = styled(Link)`
    border-radius: 50px;
    background: ${({primary}) => (primary 
        ? '#FAFAFA' : '#ff2dcd')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({ dark }) => (dark ? 
    '#010606' : '#fff')};
    font-size: ${({fontBig}) => (fontBig 
    ? '16px' : '12px')};
    outline: none;
    border: none; 
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2 ease-in-out;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => 
        (primary ? '#139aff' : '#FAFAFA' )}; 
    }
    @media only screen 
    and (device-width: 428px) 
    and (device-height: 926px) 
    and (-webkit-device-pixel-ratio: 3) {
        border-radius: 150px;
        background: ${({primary}) => (primary 
        ? '#FAFAFA' : '#ff2dcd')};
        white-space: nowrap;
        padding: 28px;
        '#010606' : '#fff')};
        font-size: 25px;
        outline: none;
        border: none; 
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2 ease-in-out;

        &:hover{
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => 
        (primary ? '#139aff' : '#FAFAFA' )}; 
    }
        
    }
`