import React from 'react';
import SignIn from '../Components/SignIn';


const SigninPage = () => {
    return (
        <>
            <SignIn />
        </>
    );
};

export default SigninPage;
