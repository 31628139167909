import styled from 'styled-components';

export const GalleryContainer2 = styled.div`
    overflow: hidden;
    width:100%;
    height:100vh;
    color: #fff;
    background: #000;
    

    @media screen and (max-width: 490px) {
        height: 100vh;
      
    }
    @media screen and (max-width: 375px) {
        height: 100vh;
       
    }
`;

export const Header = styled.div`
    // background-color: #5c0080;
    margin-bottom: 4rem;
`

export const HeaderWrapper = styled.div`
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    padding: 1rem 0;
    color: #ffffff;
  `

export const ImageGalleryWrapper = styled.div`
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    padding: 1rem 0;
    color: #ffffff;
    background: #000;
    @media only screen and (min-device-width: 375px) {
        .header-wrapper {
          padding: 1rem;
        }
`