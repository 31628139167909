import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';


export const a = styled.a`
color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
      border-bottom: 3px solid #ff2dcd;
  }
`
export const Nav = styled.nav`
  background: ${({scrollNav}) => (scrollNav ? '#000' : 'transparent' )};
  width: 100%;
  height: 80px;
  margin-top: -80px; 
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top:0;
  z-index:10;

  @media screen and (max-width: 960px) {
      transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;

  @media only screen 
and (device-width: 428px) 
and (device-height: 926px) 
and (-webkit-device-pixel-ratio: 3) { 
  height: 260px;
}
  `;

  export const NavLogo = styled(LinkR)`
  color: #fafafafa;
  justify-self: flex-start;
  cursor: pointer;
  display: flex;
  font-size: 2.2rem;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;

  @media only screen 
  and (device-width: 428px) 
  and (device-height: 926px) 
  and (-webkit-device-pixel-ratio: 3) { 
  font-size: 4.5rem;
  }

  @media screen and (max-width: 455px){
    font-size: 1.5rem;

  }
`;

  export const MobileIcon = styled.div`
    display: none;
    
    @media only screen 
    and (device-width: 428px) 
    and (device-height: 926px) 
    and (-webkit-device-pixel-ratio: 3) { 
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 4.8rem;
      cursor: pointer;
      color: #fff;
    }

    @media screen and (max-width: 834px){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }
  `;
  
  export const NavMenu = styled.ul`
  display: flex;
  algin-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px; 

  @media only screen 
  and (device-width: 428px) 
  and (device-height: 926px) 
  and (-webkit-device-pixel-ratio: 3) { 
      display: flex;
      algin-items: center;
      list-style: none;
      text-align: center;
      margin-right: -22px; 
  }
  @media only screen 
  and (device-width: 428px) 
  and (device-height: 926px) 
  and (-webkit-device-pixel-ratio: 3) { 
      display: none;
}
  @media screen and (max-width: 834px){
      display: none;
  }
  `;

  export const NavItem = styled.li`
    height: 80px;
    @media only screen 
      and (device-width: 428px) 
      and (device-height: 926px) 
      and (-webkit-device-pixel-ratio: 3) { 
      height: 320px;
    }
  `;

  export const ExternalLink = styled.a`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    &.active {
        border-bottom: 3px solid #ff2dcd;
    }
  `


  export const NavLinks = styled(LinkS)`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    &.active {
        border-bottom: 3px solid #ff2dcd;
    }

  `;
  
  export const NavBtn= styled.nav`
    display: flex;
    align-items: center;

    @media screen and (max-width: 834px) {
        display: none;
    }
  `;

  export const NavBtnLink = styled(LinkR)`
    border-radius: 50px;
    background: #D3602E;
    white-space: nowrap;
    padding: 10px 22px;
    color: #010606;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #D3602E;
    }
  `;


  export const Logo = styled.img`
    height: 150px;
    margin-right: 5px;

    @media screen and (max-width: 834px){
      height: 45px;
    }
  `;

