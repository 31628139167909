import MSW from '../Images/MSW.svg';
import contactUs from '../Images/contactUs.svg';



export const homeObjOne = {
    id: 'about',
    // lightBg: true,
    lightText: true,
    lightTextDesc: false,
    // topLine: 'LSMiAMI',
    headline: 'LS Miami',
    description: `If you're looking for the ultimate Miami boat charter experience, look no further! 
    Our company offers a wide range of boats to choose from, including jet skis, pontoons, and luxurious yachts. 
    But we don't just stop there - we also offer drone service to capture your experience in stunning 4K resolution. 
    Imagine cruising through the crystal clear waters of Miami, with a jet ski or pontoon boat, and having a drone follow you and capture it all from above. 
    Or, imagine relaxing on a luxurious yacht, sipping cocktails and taking in the breathtaking views, all while a drone captures the moment in stunning detail.
     No matter what type of boat you choose, our experienced and friendly crew will ensure that you have a safe and unforgettable time on the water. 
     So why wait? Book your Miami boat charter today and let us help you make memories that will last a lifetime!`,
    buttonLabel: 'Book',
    imgStart: false,
    img: MSW ,
    alt: 'Illustation of Ocean Drive Miami',
    dark: true,
    primary: true,
    darkText: false
};

export const homeObjTwo = {
    id: 'contactus',
    lightBg: false,
    lightText: true,
    lightTextDesc: false,
    // topLine: 'LSMIAMI',
    headline: 'Contact Us:',
    address:`Address: 6815 Biscayne Blvd STE 103 #275 33138`,
    description: 'Email: info@MiamiLandAndSeaRental.com',
    descriptionTwo: <a href="tel:7542019954">Phone Number: (754)201-9954</a>,
    buttonLabel: 'Send Email',
    imgStart: false,
    img: contactUs,
    alt: 'image of a person viewing or sending messages',
    dark: true,
    primary: true,
    darkText: false
};

