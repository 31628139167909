import styled from 'styled-components';



export const SectorContainer= styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    background-size: cover;
    background-position: left top;
    background-attachment: fixed;

    @media (max-width: 992px){
        width:100%;
        background: #000;
        background-image: none
        flex-direction: column;
        align-items: center;
    }
`;

export const SectorWrapper = styled.div`
    position: relative;
    width: 1000px;
    display: flex;
    justify-content: space-between;
    min-height: 100vh;
    flex-wrap: wrap;
    padding: 20px 0;
    @media (max-width: 992px){
        position: relative;
        width:100%;
        flex-direction: column;
        align-items: center;
    }
`;

export const SectorCard = styled.div`
    position: relative;
    height: 250px;
    
    display: flex;
    width: 45%;
    margin: 30px 0;
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
        
    @media (max-width: 992px){
        width: 500px;
        height: auto;
        flex-direction: column;
        margin: -20px 0;
        
    }
`;
export const ImgContainer = styled.div`
position: absolute;
top: 10px;
left: 10px;
background: rgba(0,0,0,0.05);
backdrop-filter: blur(20px);
height: calc(100% - 20px);
width: calc(100% - 20px);
z-index: 1;  
display: flex;
justify-content: center;
align-items: center;
overflow: hidden;
border-radius: 20px;
border: 1px solid rgba(255,255,255,0.2);
box-shadow: 5px 5px 30px rgba(0,0,0,0.1);
transition: 0.5s ease-in-out;
    ${SectorCard}:hover & {
        height: 150px;
        width: 150px;
        left: -75px;
        top: calc(50% - 75px);  
        }


    @media (max-width: 992px){
        position: relative;
        width: 80%;
        height: 200px;
        top: 100px;
        left: 10%;  
            ${SectorCard}:hover & {
            width: 80%;
            height: 200px;
            top: 100px;
            left: 10%; 
            
            top: 120px;
            }  
    }
`    
export const SectorIcon = styled.img`
    height: 100%;
    max-width: 100px;
    ${SectorCard}:hover & {
        max-width:125px;
        filter: invert(5%); 
        transition: 0.5s ease-in-out;  
        }
        @media (max-width: 992px){
           
            ${SectorCard}:hover & {
                transition: 0.5s ease-in-out;  
                max-width: 100px;
                top:120px; 
                
                }
        }
      

`;

export const Card = styled.div`
        position: relative;
        width: 100%;
        box-shadow: none;
        border-top: 100px;
`

export const SectorContent = styled.div`
position: absolute;
right: 0;
width: calc(100% - 75px);
height: 100%;
padding: 20px;
display: flex;
justify-content: center;
align-items: center;
@media (max-width: 992px){
        position: relative;
        width: 100%;
        border-top: 100px solid;
        background: rgba(0,0,0,0.7)
    }

`;

export const SectorH1 = styled.h1`
  color: #139aff;
  margin-bottom: 5px;
  font-size: 32px;
`;

export const SectorH2Card = styled.h2`
    color: #FAFAFA;
`;

export const SectorH2 = styled.h2`
    color: #FAFAFA;
`;

export const SectorP = styled.p`
    color: #FAFAFA;
`;
