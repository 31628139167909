import styled from 'styled-components';


export const InfoContainer = styled.div`
    overflow: hidden;
    width:100%;
    height:100vh;
    color: #fff;
    background: ${({lightBg}) => (lightBg ? '#8D9798' : '#000')};
    

    @media screen and (max-width: 490px) {
        height: 100vh;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    @media screen and (max-width: 375px) {
        height: 100vh;
        padding-bottom: 100px;
    }
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 1000px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`;


export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({ imgStart }) => imgStart ? `'col2 col1'` : `'col1 col2'`};

    @media screen and (max-width: 834px) {
        grid-template-areas: ${({imgStart}) => imgStart ? `'col1' 'col2'` : `'col1 col1' 
        'col2 col2'`};
    }
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
`;

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
`;

export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
`;

export const TopLine = styled.p`
    color: #D3602E;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;

`;

export const Heading= styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color:${({ lightText }) => (lightText ? '#139AFF' : '#7BE9E9')};


    /* ---------------iPhone 12 Pro MAX ---------------*/
    @media only screen
    and (device-width: 428px)
    and (device-height: 926px)
    and (-webkit-device-pixel-ratio: 3)
    {
        font-size: 23px;
    }

    
    @media screen and (max-width: 280px){
        font-size: 24px;
        font-weight: 0;
        line-height: 1.1;
        margin-top: 24px;
    }

    @media screen and (max-width: 480px){
        margin-top: 74px;
        font-size: 32px;
    }
`;

export const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: ${({ darkText }) => (darkText ? '#139aff' : "#fff")};

    /* ---------------iPhone 12 Pro MAX ---------------*/

    @media only screen
        and (device-width: 428px)
        and (device-height: 926px)
        and (-webkit-device-pixel-ratio: 3)
        {
            font-size: 23px;
        }


    // @media screen and (max-width: 482px) {
    //     font-size: 20px;
    //     line-height:28px;
    // }
    // @media only screen
    // and(device-width: 428px)
    // and(device-height: 926px)
    // and(-webkit-device-pixel-ratio: 3){
    //     font-size: 58px;
    //     line-height: 56px;
    // } 

`;

export const SubtitleTwo = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: ${({ darkText }) => (darkText ? '#139aff' : "#fff")};

    /* ---------------iPhone 12 Pro MAX ---------------*/

    @media only screen
        and (device-width: 428px)
        and (device-height: 926px)
        and (-webkit-device-pixel-ratio: 3)
        {
            font-size: 23px;
        }

`;

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`;

export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
 
    /* ---------------iPhone 12 Pro MAX ---------------*/

    @media only screen
        and (device-width: 428px)
        and (device-height: 926px)
        and (-webkit-device-pixel-ratio: 3)
        {
            width: 200px
        }

 
`;