import React, {useState} from 'react';
import Video from '../../videos/video.mp4';
import { Button } from '../ButtonElement';
import LSwords from '../Images/LSMiamiLogoAllWhite.svg';
import { 
HeroContainer,
HeroBg,
VideoBg,
HeroH1,
HeroContent,
HeroP,
HeroBtnWrapper,
ArrowForward,
ArrowRight,
SVG

} from './HeroElements';

const HeroSection = () => {
    const [hover, setHover] = useState(false);


    const onHover = () => {
        setHover(!hover)
    }
    return (
        <HeroContainer>
            <HeroBg>
                <VideoBg autoPlay loop muted src={Video} type='video/
                mp4'/>
            </HeroBg>
            <HeroContent>
                <HeroH1>
                <SVG src={LSwords}></SVG>
                </HeroH1>

                <HeroP>
                    Miami is a lifestyle
                </HeroP>
                <HeroBtnWrapper>
                    <Button
                    smooth={true} 
                    duration={500}
                    spy={true}
                    exact='true' 
                    offset={-80}
                    to='sectors'
                    onMouseEnter={onHover}
                    onMouseLeave={onHover}
                    primary='true'
                    dark='true'
                    >
                        Services {hover ? <ArrowForward /> : <ArrowRight/>}

                    </Button>
                </HeroBtnWrapper>

            </HeroContent>
        </HeroContainer>
    );
};
export default HeroSection;