import { Carousel } from 'react-carousel-minimal';
import { GalleryContainer } from './GalleryElements';

function Gallery() {
    const data = [
      //1st image
      {
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Sea-Doo_Switch_side_view.jpg/800px-Sea-Doo_Switch_side_view.jpg",
        caption: "Sea-Doo Switch Port side"
      },
      //5th image 
      {
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/de/Sea-Doo_Swtich_in_Miami_ICW_sun_glare.jpg/800px-Sea-Doo_Swtich_in_Miami_ICW_sun_glare.jpg",
        caption: "Sea-Doo Switch Starboard side"
      },
      {
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/Starboard_side_of_a_50%27_Bella_Vita_Cruiser.jpg/2560px-Starboard_side_of_a_50%27_Bella_Vita_Cruiser.jpg",
        thumbnail: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/Starboard_side_of_a_50%27_Bella_Vita_Cruiser.jpg/320px-Starboard_side_of_a_50%27_Bella_Vita_Cruiser.jpg",
        caption:"50' Bella Vita Cruiser"
      },
      {
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/50%27_Bella_Vita_Cruiser_bow_clean.jpg/800px-50%27_Bella_Vita_Cruiser_bow_clean.jpg",
        thumbnail: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/50%27_Bella_Vita_Cruiser_bow_clean.jpg/320px-50%27_Bella_Vita_Cruiser_bow_clean.jpg",
        caption: "50' Bella Vita's Bow",
      },
      {
        image: "https://upload.wikimedia.org/wikipedia/commons/9/9e/50%27_Bella_Vita_Cruiser_upper_deck_sitting_area.jpg",
        thumbnail: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/50%27_Bella_Vita_Cruiser_upper_deck_sitting_area.jpg/320px-50%27_Bella_Vita_Cruiser_upper_deck_sitting_area.jpg",
        caption: "50' Bella Vita Upper Deck"
      },
      {
      image: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/75/50%27_Bella_Vita_Cruiser_entrance_POV_of_inner_cabin.jpg/3024px-50%27_Bella_Vita_Cruiser_entrance_POV_of_inner_cabin.jpg",
      thumbnail: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/75/50%27_Bella_Vita_Cruiser_entrance_POV_of_inner_cabin.jpg/180px-50%27_Bella_Vita_Cruiser_entrance_POV_of_inner_cabin.jpg",
      caption: "50' Bella Vita Lower Quarters"
      },
      //2nd image
      {
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0d/Yamaha_EX_beside_Sea-Doo_RXTX_300.jpg/800px-Yamaha_EX_beside_Sea-Doo_RXTX_300.jpg",
        caption: "Yamaha EX"
      },
      //3rd image
      {
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Lava_Red_Sea-Doo_300_RXT-X.jpg/800px-Lava_Red_Sea-Doo_300_RXT-X.jpg",
        caption: "Sea-Doo 300 RXT-X"
      },
      //4th image 
      //6th image 
      {
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/80/RXTX-300_along_side_a_Sea-Doo_Switch.jpg/800px-RXTX-300_along_side_a_Sea-Doo_Switch.jpg",
        caption: "Sea-Doo RXTX 300 and 2 riders"
      },
      //7th image
      {
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Jet_Skis_grouped_with_a_Sea-Doo_Switch.jpg/800px-Jet_Skis_grouped_with_a_Sea-Doo_Switch.jpg",
        caption: " Yamaha EX & Sea-Doo RXTX 300 with 21' Sea-Doo Switch"
      },
      //8th image
      {
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7d/Sea-Doo_Switch.jpg/800px-Sea-Doo_Switch.jpg",
        caption: "Guests in 21' Sea-Doo Switch"
      },
    ];

  
    const captionStyle = {
      fontSize: '2em',
      fontWeight: 'bold',
    }
    const slideNumberStyle = {
      fontSize: '20px',
      fontWeight: 'bold',
    }
    return (
      <GalleryContainer>
      <div className="Gallery">
        <div style={{ backgroundColor:"black", textAlign: "center" }}>
          <h2>21' Coral Sea-Doo Switch Cruise 170hp</h2>
          <p>The Most Innovative Pontoon </p>
          <div style={{
            padding: "0px 20px"
          }}>
            <Carousel
              data={data}
              time={3500}
              width="850px"
              height="500px"
              captionStyle={captionStyle}
              radius="10px"
              slideNumber={true}
              slideNumberStyle={slideNumberStyle}
              captionPosition="bottom"
              automatic={true}
              dots={true}
              pauseIconColor="white"
              pauseIconSize="40px"
              slideBackgroundColor="darkgrey"
              slideImageFit="cover"
              thumbnails={true}
              thumbnailWidth="100px"
              style={{
                textAlign: "center",
                maxWidth: "850px",
                maxHeight: "1000px",
                margin: "0px auto",
              }}
            />
          </div>
        </div>
      </div>

      
      </GalleryContainer>

      
      
    );
  }
  export default Gallery;